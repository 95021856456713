let filter = f => {
  if (!('condition' in f)) return true
  if (typeof f.condition === 'function') return f.condition()
  return f.condition
}

function transformContent(content, transform = ['h2', 'h1']) {
  if (!content) return
  return content.replace(transform[0], transform[1])
}

function getValueFromArray(array = [], value, match) {
  return array.find(i => i[value] === match) || {}
}

function removeEmptyHTML(item) {
  //pattern for empty html tag
  const pattern = new RegExp('(((<\\w+>)+[ \n(<br>)]*(<\\/\\w+>)+)+)|<br>', 'g')

  if (!item) return
  if (item instanceof Array) {
    return item.filter(i => {
      let result = typeof i === 'string' ? i.replace(pattern, '') : i
      if (!result) return
      return result
    })
  }

  return Object.fromEntries(
    Object.entries(item).filter(([key, value]) => {
      let result = typeof value === 'string' ? value.replace(pattern, '') : value
      if (!result) return
      return {
        [key]: result,
      }
    }),
  )
}

export default function() {
  const get = path => this.$path(path, this.$store.state)

  return [
    {
      component: 'Section',
      props: {
        width: 'full',
        id: 'section-hero',
        class: 'section-hero',
        style: 'padding:0;',
      },
      blocks: {
        default: [
          {
            component: 'Banner',
            props: {
              class: 'hero',
            },
            data: {},

            blocks: {
              default: {
                component: 'Slider',
                data: get('Project.item.media.cover'),
                props: {
                  aspectRatio: '16/9',
                  class: 'hero__slider',
                  options: {
                    'navigation-placement': 'bottom',
                  },
                },
              },

              // bottom: {
              //   condition: false,
              //   component: 'Flex',
              //   props: {
              //     class: `hero__text hero__text-placement--${get(
              //       'Project.item.customFields.project-hero.placement',
              //     ) || 'center'}`,
              //   },
              // blocks: {

              //     default: [

              //       {
              //         component: 'Text',
              //         props: {
              //           textAlign: 'center',
              //         },
              //         data: {
              //           content: `<h1>${get(
              //             'Project.item.customFields.project-hero.title',
              //           )}</h1> <p>${get('Project.item.customFields.project-hero.content')}</p>`,
              //         },
              //       },
              //       {
              //         component: 'Flex',
              //         props: {
              //           alignItems: 'center',
              //         },
              //         blocks: {
              //           default: [
              //             {
              //               component: 'Text',
              //               data: {
              //                 content: (get('Project.item.customFields.project-hero-buttons') || [])
              //                   .filter(button => {
              //                     if (button?.type === 'brochure') return
              //                     return Boolean(button.content)
              //                   })
              //                   .map(
              //                     button =>
              //                       `<a target="_self" data-cta="${
              //                         button.type === 'flatfinder' ? 'secondary' : 'primary'
              //                       }" href="#${button.type}">${button.content}</a>`,
              //                   )
              //                   .join(''),
              //               },
              //             },
              //             {
              //               component: 'Brochure',
              //               condition:
              //                 (get('Project.item.media.brochure') || []).length &&
              //                 getValueFromArray(
              //                   get('Project.item.customFields.project-hero-buttons'),
              //                   'type',
              //                   'brochure',
              //                 ).type,
              //               data: get('Project.item.media.brochure'),
              //               props: {
              //                 style: 'padding-top: auto;',
              //                 placement: 'top',
              //                 label: getValueFromArray(
              //                   get('Project.item.customFields.project-hero-buttons'),
              //                   'type',
              //                   'brochure',
              //                 ).content,
              //               },
              //             },
              //           ].filter(f => filter(f)),
              //         },
              //       },
              //     ].filter(f => filter(f)),
              //   },
              //  },
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      props: {
        width: 'medium',
        id: 'section-info',
        class: 'section-info',
        style: 'padding-bottom:0;',
      },
      blocks: {
        default: [
          {
            component: 'ScrollAnchor',
            data: 'section-info',
          },

          {
            component: 'Flex',
            props: {
              flexDirection: 'column',
              class: 'section-info__content',
              style: 'opacity: 0; transform: translateX(-5rem);',
            },

            blocks: {
              default: [
                {
                  component: 'Text',
                  props: {
                    class: 'project__description',
                  },

                  data: {
                    content: transformContent(get('Project.item.description')),
                  },
                },
                {
                  condition: (get('Project.item.media.brochure') || []).length,

                  component: 'Brochure',
                  data: get('Project.item.media.brochure'),
                  props: {
                    style: 'padding-top: auto;',
                  },
                },
              ].filter(f => filter(f)),
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      condition: get('Project.item.customFields.additional-content.image'),
      props: {
        width: 'full',
        id: 'section-info-image',
        class: 'section-info-image',
        style: 'opacity: 0;',
      },
      blocks: {
        default: [
          {
            component: 'Image',
            data: {
              image: get('Project.item.customFields.additional-content.image'),
            },
            props: {
              aspectRatio: '16/9',
              class: [
                {
                  'info-image--frame': (
                    get('Project.item.customFields.additional-content.settings') || []
                  ).includes('frame'),
                },

                {
                  'info-image--round-edges': (
                    get('Project.item.customFields.additional-content.settings') || []
                  ).includes('round-edges'),
                },
              ],
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      props: {
        width: get('Project.item.customFields.additional-sections-settings.size') || 'full',
        id: 'section-image-text',
        class: 'section-image-text',
      },
      blocks: {
        default: [
          {
            props: {
              removeImageSpace: true,
              startDirection: 'odd',
            },
            component: 'ImageText',
            data: {
              content: get('Project.item.customFields.additional-sections-v2-1'),
            },
          },
        ],
      },
    },
    {
      component: 'Section',
      props: {
        width: 'full',
        id: 'section-revenues-and-costs',
        class: 'section-revenues-and-costs',
        style: 'padding-top:0;',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            data: {
              content: get('Project.item.customFields.heading-revenues-and-costs'),
            },
            props: {
              class: 'revenues-and-costs__heading',
            },
          },

          {
            component: 'RevenuesAndCosts',
            props: {
              style: 'color:white;',
            },
            data: {
              content: get('Project.item.customFields.revenues-and-costs'),
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      props: {
        width: get('Project.item.customFields.additional-sections-settings.size') || 'full',
        id: 'section-image-text',
        class: 'section-image-text',
      },
      blocks: {
        default: [
          {
            props: {
              removeImageSpace: true,
              startDirection: 'even',
            },
            component: 'ImageText',
            data: {
              content: get('Project.item.customFields.additional-sections-v2-2'),
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      props: {
        width: 'medium',
        id: 'section-flatfinder',
        class: 'section-flatfinder',
        style: 'padding-bottom:1rem',
      },
      blocks: {
        default: [
          {
            component: 'ScrollAnchor',
            data: 'flatfinder',
          },
          {
            component: 'Text',
            props: {
              class: 'flatfinder__top-content',
            },

            data: {
              content: transformContent(get('Project.item.customFields.flatfinder-top-content')),
            },
          },
          {
            component: 'Flatfinder',
            data: {
              properties: get('Project.item.metadata.siteSettings.featuredResidentialProperties'),
              flatfinders: get('Project.item.flatfinders'),
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      props: {
        width: 'medium',
        id: 'section-residentials',
        class: 'section-residentials',
        style: 'padding-top:1rem',
      },
      blocks: {
        default: [
          {
            component: 'ScrollAnchor',
            data: 'residentials',
          },
          {
            component: 'Residentials',
            data: {
              project: get('Project.item.id'),
            },
            props: {
              title: get('Project.item.customFields.title-overrides.residentials'),
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      condition: get('Project.item.customFields.residential-templates.references.length'),
      props: {
        width: 'medium',
        id: 'section-residential-templates',
        class: 'section-residential-templates',
      },
      blocks: {
        default: [
          {
            component: 'ScrollAnchor',
            data: 'residential-templates',
          },
          {
            component: 'ResidentialTemplates',
            data: {
              content: get('Project.item.customFields.residential-templates'),
            },
          },
        ],
      },
    },

    // {
    //   component: 'Section',
    //   props: {
    //     width: 'medium',
    //     id: 'section-attachments',
    //     class: 'section-attachments',
    //   },
    //   blocks: {
    //     default: [
    //       {
    //         component: 'ScrollAnchor',
    //         data: 'attachments',
    //       },
    //       {
    //         component: 'Attachments',
    //         data: get('Project.item.media.attachments'),
    //       },
    //     ],
    //   },
    // },
    {
      component: 'Section',
      props: {
        width: 'medium',
        class: 'section-gallery',
      },
      blocks: {
        default: [
          {
            props: {
              variant: 'masonry',
            },
            component: 'Gallery',
            data: {
              images: get('Project.item.media.gallery'),
            },
          },
        ],
      },
    },

    // {
    //   component: 'Section',
    //   props: {
    //     width: 'full',
    //     id: 'section-map',
    //     class: 'section-map',
    //     style: 'padding-bottom:0;',
    //   },
    //   blocks: {
    //     default: [
    //       {
    //         component: 'ScrollAnchor',
    //         data: 'map',
    //       },
    //       {
    //         component: 'Map',
    //         props: {
    //           aspectRatio: '21/9',
    //         },
    //         data: {
    //           ...get('Project.item.address'),
    //           coordinates: get('Project.item.address.location.coordinates'),
    //         },
    //       },
    //     ],
    //   },
    // },

    {
      component: 'Section',
      props: {
        width: 'medium',
        id: 'section-contact',
        class: 'section-contact',
      },
      blocks: {
        default: [
          {
            component: 'ScrollAnchor',
            data: 'lead',
          },
          {
            component: 'Grid',
            props: {
              gap: '4rem 8rem',
              columns: '2:1',
            },
            blocks: {
              default: [
                {
                  component: 'Lead',
                  props: {
                    options: {
                      placeholders: {
                        'contact.name': this.$t('name'),
                        'contact.email': this.$t('email'),
                        'contact.phone': this.$t('phone'),
                      },
                      fields: ['!title'],
                      actionLabel: this.$t('send'),
                    },
                  },
                  data: {
                    project: get('Project.item.id'),
                    reference: { onModel: 'Project', ref: get('Project.item.id') },
                  },
                  blocks: {
                    top: [
                      {
                        component: 'Text',
                        data: {
                          content: get('Project.item.customFields.lead-top-content'),
                        },
                      },
                    ],
                  },
                },
                {
                  component: 'Flex',
                  props: {
                    flexDirection: 'column',
                    gap: '2rem',
                  },
                  blocks: {
                    default: [
                      {
                        component: 'Openhouse',
                        props: {
                          class: 'project-openhouse',
                        },
                        data: {
                          content: get('Project.item.openhouses'),
                        },
                        condition: () => {
                          if (!this.item) return false
                          if (get('Project.item.openhouses.length')) return true
                          return !get('Root.item.metadata.siteSettings.hideNoOpenhouse')
                        },
                      },
                      {
                        component: 'Roles',
                        data: {
                          content: get('Project.item.roles.salesman'),
                        },
                        props: {
                          class: 'contact-page__salesman',
                          showLogo: false,
                        },
                      },
                    ].filter(f => filter(f)),
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ].filter(f => filter(f))
}
