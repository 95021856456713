<template>
  <div v-if="value.length" class="revenues-and-costs">
    <template v-for="(item, index) in value">
      <div class="revenues-and-costs__item" :key="index">
        <span class="revenues-and-costs__item-top">
          <span>{{ item.title }}</span>
          <span class="revenues-and-costs__item-top-price">{{ item.price }}</span>
        </span>
        <div class="revenues-and-costs__item-center" v-html="item.content" />
        <div
          v-for="(line, index) in item.lines"
          :key="index"
          class="revenues-and-costs__item-lines"
        >
          <template v-for="(lineItem, index) in line.line">
            <div :key="index + 'lineItem'">
              {{ lineItem }}
            </div>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import ImageComponent from '@kvass/media-render/Types/Image'
export default {
  props: {
    data: Object,
    startDirection: {
      type: String,
      default: 'odd',
      enums: ['odd', 'even'],
    },
    removeImageSpace: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    value() {
      return (this.$path('content', this.data) || []).map(i => {
        return {
          ...i,
          lines: i.lines
            .split('</p>')
            .filter(Boolean)
            .map(line => {
              line = line.replace('<p>', '')
              return {
                line: line.split(':'),
              }
            }),
        }
      })
    },

    style() {
      return {
        'grid-column-end': 'span 2',
      }
    },
  },
  components: {
    ImageComponent,
  },
}
</script>

<style lang="scss">
.revenues-and-costs {
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  gap: 2rem;
  color: white;

  @include respond-below('tablet') {
    flex-direction: column;
  }

  &__item {
    background-color: var(--primary);
    padding: 3rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;

    &-top {
      justify-content: space-between;
      display: flex;
      gap: 2rem;
      &-price {
        color: var(--custom-primary-contrast);
        font-style: italic;
      }
    }
    &-middle {
      display: flex;
      gap: 2rem;
    }
    &-lines {
      display: flex;
      gap: 2rem;
      justify-content: space-between;
      &:nth-child(4) {
        color: var(--custom-primary-contrast);
      }
    }
  }
}
</style>
