<template>
  <Loader :value="promises.fetch" theme="default">
    <div class="project-page">
      <Blocks ref="blocks" :blocks="config" :custom-components="customComponents" />
    </div>
  </Loader>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ProjectComponents from '@/components/Project'
import CustomComponents from '@/components/Custom'

import BrowserApiMixin from '@/mixins/browser-api'
import { GetCustomField } from '@/utils'
import { Blocks } from '@kvass/pagebuilder'
import Config from '../config/project'
import { animate, inView, stagger } from 'motion'

export default {
  mixins: [BrowserApiMixin('project')],

  computed: {
    ...mapState('Project', ['promises', 'item']),
    config() {
      return Config.call(this)
    },
    customComponents() {
      return {
        ...CustomComponents,
        ...ProjectComponents,
      }
    },
  },

  methods: {
    ...mapActions('Project', ['fetch']),
    GetCustomField,
    async initAnimation() {
      animate(
        '.hero__text ',
        { opacity: 1, transform: 'none' },
        { duration: 0.8, delay: stagger(0.3) },
      )

      inView('.section-info-image', ({ target }) => {
        animate(target, { opacity: 1, transform: 'none' }, { duration: 0.8 })
      })

      inView(
        '.section-info__content',
        ({ target }) => {
          animate(target, { opacity: 1, transform: 'none' }, { duration: 0.8 })
        },
        { margin: '0px 0px -200px 0px' },
      )

      inView('.kvass-image-text__item', ({ target }) => {
        animate(target, { opacity: 1, transform: 'none' }, { duration: 0.8 })
      })
    },
  },
  created() {
    this.fetch()
  },
  mounted() {
    this.promises.fetch.then(() => this.$nextTick(() => this.initAnimation()))
  },

  metaInfo() {
    return {
      title: this.$t('home'),
    }
  },

  components: {
    ...ProjectComponents,
    Blocks,
  },
}
</script>

<style lang="scss">
.nav--project-expanded + .elder-loader {
  .cta-banner {
    display: none;
  }
}
.project-page {
  @for $i from 1 through 15 {
    & > *:nth-child(#{$i}) {
      order: $i * 10;
    }
  }

  .kvass-image-text__item {
    opacity: 0;
  }

  .section-residentials {
    .kpb-section__container {
      @include respond-below('phone') {
        padding: 0;
      }
    }
  }
  .section-gallery {
    img {
      aspect-ratio: 16/9;
    }
    @include respond-above('phone') {
      .kpb-gallery {
        grid-template-columns: repeat(3, 1fr) !important;
        .kpb-gallery__item:nth-child(1) {
          grid-column-end: initial !important;
        }

        .kpb-gallery__item:nth-child(2n + 4) {
          grid-column-end: span 2;
        }
        .kpb-gallery__item:last-child:nth-child(even) {
          grid-column-end: span 3;
        }
      }
    }
  }

  .section-info-image,
  .section-info {
    .kpb-image__image {
      aspect-ratio: 16/9;
    }
    @include respond-below('phone') {
      padding: 2rem 1rem;
    }
    &__container {
      @include respond-below('phone') {
        padding: 0;
      }
    }
  }

  .kpb-blocks {
    .section-info {
      margin-bottom: 1em;

      &__content {
        p:last-child {
          a[href='#lead'] {
            font-size: 1.2rem;
          }
        }
        @include respond-above('phone') {
          h1:first-child {
            text-align: center;
          }
          p:nth-child(1),
          p:nth-child(2) {
            text-align: center;
            margin-bottom: 2em;
          }
          p:last-child {
            text-align: center;
            margin-top: 2rem;
          }
        }
      }
    }

    .section-info-image {
      .kpb-section__container {
        @include respond-below('phone') {
          padding: 0;
        }
      }
      .info-image {
        &--frame {
          img {
            border: 3px solid var(--primary);
            padding: 0.4rem;
            background: transparent;
          }
        }
        &--round-edges {
          $radius: 3rem;
          img {
            border-radius: $radius;
          }

          .kvass-media-render-image__caption {
            border-radius: $radius;
          }

          @include respond-below('phone') {
            img {
              border-radius: calc(#{$radius} - 1rem);
            }
            .kvass-media-render-image__caption {
              border-radius: calc(#{$radius} - 1rem);
            }
          }
        }
      }
    }
  }

  .revenues-and-costs__heading {
    max-width: 1200px;
    margin-bottom: 4rem;
    margin-inline: auto;
    padding-left: 1rem;

    @include respond-below('phone') {
      margin: 2rem 1rem;
    }
  }
  .kpb-image {
    position: relative;
    &__caption {
      position: absolute;
      bottom: 0.5rem;
      left: 0.5rem;
      padding: 0.5rem 0.8rem;
      background-color: css-alpha('primary', 100%);
      color: white;
      border-radius: 5px;
      transition: opacity 200ms ease-out;
      pointer-events: none;
      width: initial;

      border: none;

      @include respond-below('phone') {
        font-size: 0.7em;
        padding: 0.1rem 0.8em;
        bottom: 0.2rem;
        left: 0.2rem;
      }
    }
  }
}
</style>
